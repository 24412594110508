import React, { useState } from 'react'
import PropTypes from 'prop-types';

import {selectLimit} from '../utils/limits';
import Fetch from './fetch';

//destructure props
    export default function Entities({route}){

    const [oset, setOset] = useState(1);
    const [limit, setLimit] = useState(parseInt(selectLimit[0],10));
    function handlePrev(){
        setOset((oset) => (oset > 1 ? --oset : oset));
	//console.log("Entities:handlePrev(): oset: "+oset);
    }
    function handleNext(){
        setOset((oset) => ++oset);
	//console.log("Entities:handlePrev(): oset: "+oset);
    }
    return (
	<>
	    <label>
		Page offset:{oset}
	    <button
	    	onClick={e => handlePrev()}
	    >
		prev page
	    </button>
	    </label>
	    <button
	    	onClick={e => handleNext()}
	    >
		next page
	    </button>
	    <label>
		Enter limit:
		<input
		    maxLength="3"
		    name="limit"
		    onChange={e => setLimit((limit) => (parseInt(e.target.value,10) > 1 ? parseInt(e.target.value,10) : 1))}
		    placeholder={selectLimit[0]}
		    type="text"
		    value={limit}
		/>
	    </label>
            <Fetch
		oset={oset}
		limit={limit}
		route={route}
	    />
	</>
    );
}
Entities.propTypes = {
    route: PropTypes.string
};
