import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import config from '../utils/config';
import Map from './map';
import Table from './table';

/*destructure props object*/
export default function Fetch ({limit,oset,route}) {

    const [array, setArray] = useState([]);

    /*fetch array in a JavaScript function*/
    const fetch = async () => {
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
            //fetch data only if user selection is unequal default value
            const address = `${config.API}${route}?oset=${oset}&limit=${limit}`;
	    //console.log("Fetch:fetch(): address: "+address);
            const res = await axios.get(address);
	    setArray((array) => res.data);
        } catch (err) {
            console.error('Fetch: fetch() err.message: ' + err.message);
	    setArray((array) => []);
        }
    };

    useEffect(() => {
	/*effect goes here*/
	fetch();
	/*use an empty dependency array to ensure the hook is running only once*/
	/*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [oset,limit]);


    if (array !== undefined || array !== null || array.length > 0) {
	/*return a React element*/
	return (
            <>
		<Map
		    array={array}
		/>
		<Table
		    array={array}
		/>
            </>
	);
    }else{
	return (
            <>
	    <p>Fetch loading...</p>
            </>
	);
    }
};
Fetch.propTypes = {
    offset: PropTypes.number,
    limit: PropTypes.number,
    route: PropTypes.string
};
