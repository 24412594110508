import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {
    getGtfsStopsCountDeState,
    getGtfsStopsCountNotGiDeState
} from '../utils/api';

    /*destructure props object*/
export default function State ({state}) {

    const [countStops, setCountStops] = useState('loading...');

    const [countStopsNotDhid, setCountStopsNotDhid] = useState('loading...');

    const fetchStops = async () => {
	let count=await getGtfsStopsCountDeState(state)
	if(count > -1){
	    setCountStops(count);
	}
    };

    const fetchStopsNotDhid = async () => {
	let count=await getGtfsStopsCountNotGiDeState(state);
	if(count > -1){
	    setCountStopsNotDhid(count);
	}
    };

    useEffect(() => {
	/*effect goes here*/
	fetchStops();
	fetchStopsNotDhid();
	/*use an empty dependency countStopsGtfs to ensure the hook is running only once*/
	/*TODO study dependency countStopsGtfs: https://reactjs.org/docs/hooks-effect.html*/
    }, []);

    if (state !== undefined || state !== null || state === '') {
	const linkStopNames = '/' + state.toLowerCase();
	//console.log('State: linkStopNames: ' + linkStopNames);
	/*return a React element*/
	return (
            <>
		<p>
		    DE-
		    <Link to={linkStopNames}>{state.toUpperCase()}</ Link>: Stops not compliant with the Global ID:&nbsp;
		    {countStopsNotDhid} ({countStops !== 0 ? (countStopsNotDhid / countStops * 100).toFixed(3) : 'loading...'} %)
		    (Stops overall:&nbsp;
		    {countStops})
		</p>
            </>
	);
    }else{
	return (
            <>
	    <p>Loading...</p>
            </>
	);
    }
};
State.propTypes = {
    state: PropTypes.string
};
