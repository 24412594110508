import React from 'react';

import Entities from '../components/entities';

const StopsNonConforming = () => {
    return (
	<>
	    <p>Please feel free to browse through stops inside DE not compliant with the Global ID.</p>
	    <Entities route = 'gtfs-stops-de-not-dhid'/>
	</>
    );
}

export default StopsNonConforming
