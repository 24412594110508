import React from 'react';
import PropTypes from 'prop-types';

import TableEntries from './table-stop-names-entries';

/*destructure props object*/
export default function TableStopNames ({array}){
    if (array == undefined || array == null || array.length < 1) {
	return (
            <>
	    <p>Loading...</p>
            </>
	);
    }else{
	return (
            <>
            <table>
		<thead>
		    <tr>
			<th>count_id</th>
			<th>count_no_dhid</th>
			<th>count_dhid</th>
			<th>stop_name</th>
		    </tr>
		</thead>
		<tbody>
		    <TableEntries array={array} />
		</tbody>
	    </table>
            </>
	);
    }
};
TableStopNames.propTypes = {
    array: PropTypes.array
};
