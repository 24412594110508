import React from 'react';
import PropTypes from 'prop-types';

function TableStopNamesEntries ({array}) {
    if (array !== undefined || array !== null || array.length > 0) {
        //iterate over array
        return array.map((item, index) => {
            return (
		<tr
		    key={item.stop_name}
		>
		    <td>{item.count_id}</td>
		    <td>{item.count_no_dhid}</td>
		    <td>{item.count_dhid}</td>
		    <td>{item.stop_name}</td>
		</tr>
	    );
        });
    }else{
	//data is empty
	return null;
    }
}
TableStopNamesEntries.propTypes = {
    array: PropTypes.array
};
export default TableStopNamesEntries;
