import React from 'react';
import PropTypes from 'prop-types';
import {MapContainer,Polyline,TileLayer} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

/*JS module import (vs cdn or style link)*/
import 'leaflet/dist/leaflet.css'
import './entities-map.css';

import EntitiesMarker from './entities-marker';

export default function EntitiesMap({entities}) {
    /*lat and lon of Braunschweig,DE*/
    const position = [52.26594, 10.52673]
    return (
	<>
	    <MapContainer
		center={position}
		zoom={7}
		minZoom={4}
		maxZoom={18}
		maxBounds={[[-60, -180], [85, 180]]}
		scrollWheelZoom={true}
	    >

		<TileLayer
		    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		/>

		<MarkerClusterGroup
		    chunkedLoading
		    >
		{
		    entities.map(function(value,key) {
			if(value.stop_lat!==undefined && value.stop_lat!==null && value.stop_lon!==undefined && value.stop_lon!==null){
			return <EntitiesMarker
				   key={value.stop_id}
				   index={value.stop_id}
				   id={value.stop_id}
				   lat={value.stop_lat}
				   lon={value.stop_lon}
				   name={value.stop_name}
			       />;
			}
                    })
		}
		</MarkerClusterGroup>
	    </MapContainer>
	</>
    );
}
EntitiesMap.propTypes = {
    entities: PropTypes.array
};
