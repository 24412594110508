import React from 'react';
import PropTypes from 'prop-types';

import TableEntries from './table-entries';

/*destructure props object*/
export default function Table ({array}){
    if (array == undefined || array == null || array.length < 1) {
	return (
            <>
	    <h2>Table</h2>
	    <p>Loading...</p>
            </>
	);
    }else{
	return (
            <>
	    <h2>Table</h2>
            <table>
		<thead>
		    <tr>
			<th>id</th>
			<th>name</th>
			<th>lat</th>
			<th>lon</th>
		    </tr>
		</thead>
		<tbody>
		    <TableEntries array={array} />
		</tbody>
	    </table>
            </>
	);
    }
};
Table.propTypes = {
    array: PropTypes.array
};
