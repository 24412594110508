// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*set up the height of*/
.leaflet-container {
  height: 45vh;
}
`, "",{"version":3,"sources":["webpack://./app/components/map/entities-map.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,YAAY;AACd","sourcesContent":["/*set up the height of*/\n.leaflet-container {\n  height: 45vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
