import React from 'react';
import PropTypes from 'prop-types';

function TableEntries ({array}) {
    if (array !== undefined || array !== null || array.length > 0) {
        //iterate over array
        return array.map((item, index) => {
            return (
		<tr
		    key={item.stop_id}
		>
		    <td>{item.stop_id}</td>
		    <td>{item.stop_name}</td>
		    <td>{item.stop_lat}</td>
		    <td>{item.stop_lon}</td>
		</tr>
	    );
        });
    }else{
	//data is empty
	return null;
    }
}
TableEntries.propTypes = {
    array: PropTypes.array
};
export default TableEntries;
