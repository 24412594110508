import axios from 'axios';

import config from './config';

export async function getStopsCountGtfs(){
    ////console.log('api:getStopsCountGtfs() Start...');

    let count=null;
    try {
	//TODO handle errors: https://www.valentinog.com/blog/await-react/
	const address = `${config.API}stops-count-gtfs`;
	const res = await axios.get(address);
	count=res.data[0].count;
    } catch (err) {
        console.error('err.message: ' + err.message);
	count=-1;
    }
    ////console.log('api:getStopsCountGtfs() Done.');
    return count;
};

export async function getStopsCountGtfsDe(){
    ////console.log('api:getStopsCountGtfsDe() Start...');

    let count=null;
    try {
	//TODO handle errors: https://www.valentinog.com/blog/await-react/
	const address = `${config.API}stops-count-gtfs-de`;
	const res = await axios.get(address);
	count=res.data[0].count;
    } catch (err) {
        console.error('err.message: ' + err.message);
	count=-1;
    }
    ////console.log('api:getStopsCountGtfsDe() Done.');
    return count;
};

export async function getStopsCountGtfsDeNotDhid(){
    ////console.log('api:getStopsCountGtfsDeNotDhid() Start...');

    let count=null;
    try {
	//TODO handle errors: https://www.valentinog.com/blog/await-react/
	const address = `${config.API}stops-count-gtfs-de-not-dhid`;
	const res = await axios.get(address);
	count=res.data[0].count;
    } catch (err) {
        console.error('err.message: ' + err.message);
	count=-1;
    }
    ////console.log('api:getStopsCountGtfsDeNotDhid() Done.');
    return count;
};

export async function getGtfsStopsLastVacuum(){
    //console.log('api:getGtfsStopsLastVacuum() Start...');

    let date=null;
    try {
	//TODO handle errors: https://www.valentinog.com/blog/await-react/
	const address = `${config.API}gtfs-stops-last-vacuum`;
	const res = await axios.get(address);
	date=res.data[0].last_vacuum;
	//console.log('api:getGtfsStopsLastVacuum() date: ' + date);
    } catch (err) {
        console.error('err.message: ' + err.message);
	date=-1;
    }
    //console.log('api:getGtfsStopsLastVacuum() Done.');
    return date;
};

export async function getStopsCountGtfsLt0(){
    ////console.log('api:getStopsCountGtfsLt0() Start...');

    let count=null;
    try {
	//TODO handle errors: https://www.valentinog.com/blog/await-react/
	const address = `${config.API}stops-count-gtfs-lt-0`;
	const res = await axios.get(address);
	count=res.data[0].count;
	//console.log('api:getStopsCountGtfsLt0() count: ' + count);
    } catch (err) {
        console.error('err.message: ' + err.message);
	count=-1;
    }
    ////console.log('api:getStopsCountGtfsLt0() Done.');
    return count;
};

/**
 * @param state string of state in DE as two lowercase characters
 * @return count of stops inside this state as int
 */
export async function getGtfsStopsCountDeState(state){
    ////console.log('api:getGtfsStopsCountDeState() Start...');
    //console.log('api:getGtfsStopsCountDeState() state: ' + state);

    let count=null;
    try {
	//TODO handle errors: https://www.valentinog.com/blog/await-react/
	const address = `${config.API}stops-count-gtfs-de-` + state;
	//console.log('api:getGtfsStopsCountDeState() address: ' + address);
	const res = await axios.get(address);
	count=res.data[0].count;
    } catch (err) {
        console.error('err.message: ' + err.message);
	count=-1;
    }
    ////console.log('api:getGtfsStopsCountDeState() Done.');
    return count;
};

/**
 * @param state string of state in DE as two lowercase characters
 * @return count of stops inside this state as int missing Global Id
 */
export async function getGtfsStopsCountNotGiDeState(state){
    ////console.log('api:getGtfsStopsCountNotGiDeState() Start...');

    let count=null;
    try {
	//TODO handle errors: https://www.valentinog.com/blog/await-react/
	const address = `${config.API}stops-count-gtfs-de-` + state + '-not-dhid';
	//console.log('api:getGtfsStopsCountNotGiDeState() address: ' + address);
	const res = await axios.get(address);
	count=res.data[0].count;
    } catch (err) {
        console.error('err.message: ' + err.message);
	count=-1;
    }
    ////console.log('api:getGtfsStopsCountNotGiDeState() Done.');
    return count;
};
