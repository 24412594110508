import React from 'react';
import PropTypes from 'prop-types';

import EntitiesMap from './map/entities-map';

/*destructure props object*/
export default function Map ({array}){
    if (array == undefined || array == null || array.length < 1) {
	return (
            <>
		<h2>Map</h2>
		<p>Loading...</p>
            </>
	);
    }else{
	return (
            <>
		<h2>Map</h2>
		<EntitiesMap entities={array} />
            </>
	);
    }
};
Map.propTypes = {
    array: PropTypes.array
};
