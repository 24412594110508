/**
 * http get request
 *
 * @param pth path
 */
export async function get(pth) {
    const data = await fetch(pth, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const jsonData = await data.json();
    return jsonData;
}
