import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import {get} from '../utils/request';
import Table from '../components/table-stop-names';

export default function StopNames() {

    // Get the userId param from the URL.
    const { state } = useParams();

    const [countNoGi, setCountNoGi] = useState('loading...');
    const [countPartGi, setCountPartGi] = useState('loading...');
    const [namesNoGi, setNamesNoGi] = useState([]);
    const [namesPartGi, setNamesPartGi] = useState([]);

    useEffect(() => {
	//console.log('StopNames:useEffect() state: ' + state);

	let address = 'https://www.v1delfi.api.swingbe.de/stop-names-count-no-gi?state=' + state;
	//console.log('StopNames:useEffect() address: ' + address);

    	//get count no gi
	get(address)
	    .then(data => {
		setCountNoGi((countPartGi) => data[0].count);
	    });

	address = 'https://www.v1delfi.api.swingbe.de/stop-names-count-part-gi?state=' + state;
	//console.log('StopNames:useEffect() address: ' + address);

    	//get count part gi
	get(address)
	    .then(data => {
		setCountPartGi((countNoGi) => data[0].count);
	    });

	address = 'https://www.v1delfi.api.swingbe.de/stop-names-no-gi?state=' + state;
	//console.log('StopNames:useEffect() address: ' + address);
	
    	//get names no gi
	get(address)
	    .then(data => {
		setNamesNoGi((namesNoGi) => data);
	    });


	address = 'https://www.v1delfi.api.swingbe.de/stop-names-part-gi?state=' + state;
	//console.log('StopNames:useEffect() address: ' + address);
	
    	//get names part gi
	get(address)
	    .then(data => {
		setNamesPartGi((namesPartGi) => data);
	    });

    }, []);

    return (
	<>
	    <p>
		{countNoGi} stops do not use a Global ID as stop_name for any platform/ quay.
	    </p>
	    <Table array={namesNoGi} />
	    <p>
		{countPartGi} stops use a Global ID as stop_name for some platforms/ quays.
	    </p>
	    <Table array={namesPartGi} />
	</>
    );
}
