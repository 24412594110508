import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Header from './components/header';
import Home from './pages/home';
import StopsNonConforming from './pages/stops-non-conforming';
import StopsRefined from './pages/stops-refined';
import StopNames from './pages/stop-names';

import {
    getGtfsStopsLastVacuum
} from './utils/api';

import packageInfo from '../package.json'
const VERSION = packageInfo.version;

export default function App(){

    const [gtfsStopsLastVacuum, setGtfsStopsLastVacuum] = useState('loading...');

    const fetch = async () => {
	let rsps=await getGtfsStopsLastVacuum();
	setGtfsStopsLastVacuum((gtfsStopsLastVacuum) => rsps);
    };

    useEffect(() => {
	/*effect goes here*/

	fetch();
	/*use an empty dependency countStopsGtfs to ensure the hook is running only once*/
	/*TODO study dependency countStopsGtfs: https://reactjs.org/docs/hooks-effect.html*/
    }, []);


    return (
	    <Router>
	    <Header />
		<h1>DELFI-Display</h1>
		<p>
		    This website (Version:&nbsp;{VERSION}) dislays analysis results made on public transport data provided by &nbsp;
      		    <a href="https://www.delfi.de/"
		       target="_blank" rel="noopener noreferrer"
		    >
			DELFI
		    </a>&nbsp;
		    last updated on &nbsp;{gtfsStopsLastVacuum}.
		</p>
		<h2>GTFS Static Feed</h2>
		<p>
		    The so called "DELFI-Datensatz GTFS" is a "GTFS Schedule" feed provided by DELFI accessible either via the &nbsp;
      		    <a href="https://www.opendata-oepnv.de"
		       target="_blank" rel="noopener noreferrer"
		    >
			opendata-oepnv.de
		    </a> or the &nbsp;
      		    <a href="https://mobilithek.info/offers/552578819783815168"
		       target="_blank" rel="noopener noreferrer"
		    >
			mobilithek.info
		    </a> data access point.
		</p>
		<Routes>
		    <Route exact path='/' element={<Home/>}/>
		    <Route exact path='/stops-non-compliant' element={<StopsNonConforming/>}/>
		    <Route exact path='/stops-refined' element={<StopsRefined/>}/>
		    <Route path="/:state" element={<StopNames />} />
		    <Route path="*" element={<Navigate to="/" />} />
		</Routes>
	    </Router>
    );
}
