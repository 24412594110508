import React from 'react';
import PropTypes from 'prop-types';
import {Popup} from 'react-leaflet';

export default function EntitiesPopup({id,lat,lon,name}){
    return (
	<>
	    <Popup>
		id: {id} <br/>
		lat: {lat} <br/>
		lon: {lon} <br/>
		name: {name}
	    </Popup>
	</>
    );
};
EntitiesPopup.propTypes = {
    id: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    name: PropTypes.string
};
