import React from 'react'
import { Link} from 'react-router-dom';

export default function Header(){

    return (
	<>
	    <Link
	    to='/'
	    >
		<button>
		    Home
		</button>
	    </Link>
	    <Link
		title="imprint"
		target="_blank"
		rel="noopener noreferrer nofollow"
		to='https://www.swingbe.de/imprint/'
	    >
		<button>
		    Imprint
		</button>
	    </Link>
	    <Link
		title="privacy policy"
		target="_blank"
		rel="noopener noreferrer nofollow"
		to='https://www.swingbe.de/privacy-policy/'
	    >
		<button>
		    Privacy Policy
		</button>
	    </Link>
	    <Link
		title="source"
		target="_blank"
		rel="noopener noreferrer nofollow"
		to='https://git.wtf-eg.de/dancesWithCycles/delfi'
	    >
		<button>
		    Source
		</button>
	    </Link>
	</>
    );
};
