import React from 'react';
import PropTypes from 'prop-types';
import {Marker} from 'react-leaflet';

import PopupElem from './entities-popup';

import { icon } from "../icons/icon-default";

export default function EntitiesMarker({id,lat,lon,name}){
    if(lat===undefined || lat===null || lon===undefined || lon===null){
	console.error('lat or lon undefined or null');
	return null;
    }else{
	if(lat===undefined||lat===null){
	    //TODO Handle issue!
	    console.error('ERROR: lat undefined or null');
	    return null;
	}else if(lon===undefined||lon===null){
	    //TODO Handle issue!
	    console.error('ERROR: lon undefined or null');
	    return null;
	}else{
	    return(
	    <>
		<Marker
		    key={id}
		    position={[lat,lon]}
		    icon={icon}
		>
		    <PopupElem
			id={id}
			lat={lat}
			lon={lon}
			name={name}
		    />
		</Marker>
	    </>
	);
	}
    }
};
EntitiesMarker.propTypes = {
    id: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    name: PropTypes.string    
};
