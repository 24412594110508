import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom';

import State from '../components/state';
import {
    getStopsCountGtfs,
    getStopsCountGtfsDe,
    getStopsCountGtfsDeNotDhid,
    getStopsCountGtfsLt0
} from '../utils/api';

export default function Home(){

    const [countStopsGtfs, setCountStopsGtfs] = useState('loading...');
    const [countStopsGtfsLt0, setCountStopsGtfsLt0] = useState('loading...');
    const [countStopsGtfsDe, setCountStopsGtfsDe] = useState('loading...');
    const [countStopsGtfsDeNotDhid, setCountStopsGtfsDeNotDhid] = useState('loading...');

    const fetchGtfs = async () => {
	let count=await getStopsCountGtfs();
	if(count > -1){
	    setCountStopsGtfs((countStopsGtfs) => count);
	}
    };

    const fetchGtfsLt0 = async () => {
	let count=await getStopsCountGtfsLt0();
	if(count > -1){
	    setCountStopsGtfsLt0((countStopsGtfsLt0) => count);
	}
    };

    const fetchGtfsDe = async () => {
	let count=await getStopsCountGtfsDe();
	if(count > -1){
	    setCountStopsGtfsDe((countStopsGtfsDe) => count);
	}
    };

    const fetchGtfsDeNotDhid = async () => {
	let count=await getStopsCountGtfsDeNotDhid();
	if(count > -1){
	    setCountStopsGtfsDeNotDhid((countStopsGtfsDeNotDhid) => count);
	}
    };

    useEffect(() => {
	/*effect goes here*/
	fetchGtfs();
	fetchGtfsLt0();
	fetchGtfsDe();
	fetchGtfsDeNotDhid();
	/*use an empty dependency countStopsGtfs to ensure the hook is running only once*/
	/*TODO study dependency countStopsGtfs: https://reactjs.org/docs/hooks-effect.html*/
    }, []);

    return (
	<>
	    <p>
		Number of entries in the stops.txt file no matter the&nbsp;
	    <Link
		title="gtfs stops location type"
		target="_blank"
		rel="noopener noreferrer nofollow"
		to='https://gtfs.org/schedule/reference/#stopstxt'
	    >
		location type
	    </Link>
		:&nbsp;
		{countStopsGtfs}
	    </p>
	    <p>
		Starting here, only stops with location_type="0" are considered for further data analysis:&nbsp;
		{countStopsGtfsLt0}
	    </p>
	    <p>
		DE: Stops not compliant with the Global ID:&nbsp;
		{countStopsGtfsDeNotDhid} ({countStopsGtfsDe !== 0 ? (countStopsGtfsDeNotDhid / countStopsGtfsDe * 100).toFixed(3) : 'loading...'} %)
		(Stops overall:&nbsp;
		{countStopsGtfsDe})
	    </p>
	    <State state='bb' />
	    <State state='be' />
	    <State state='bw' />
	    <State state='by' />
	    <State state='hb' />
	    <State state='he' />
	    <State state='hh' />
	    <State state='mv' />
	    <State state='ni' />
	    <State state='nw' />
	    <State state='rp' />
	    <State state='sh' />
	    <State state='sl' />
	    <State state='sn' />
	    <State state='st' />
	    <State state='th' />
	    <h3>
	    <Link to="/stops-non-compliant">
		Browse through stops inside DE not compliant with the Global ID.
	    </Link>
	    </h3>
	    <h3>
	    <Link to="/stops-refined">
		Browse through stops inside DE refined to be compliant with the Global ID.
	    </Link>
	    </h3>
	</>
    );
}
